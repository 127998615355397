import React from 'react';

const ProductInquireUsModal = (props) => {
    let unique_key = 0;

    let opts__inquire_time = [
        {value: 1, label: props.lang === 'en' ? 'Phone 09:00-12:00' : '電話 09:00-12:00'},
        {value: 2, label: props.lang === 'en' ? 'Phone 13:00-17:00' : '電話 13:00-17:00'},
        {value: 3, label: 'Email'},
    ];

    return (
        <div key={unique_key++} style={{width:'20rem', margin:'auto'}}>
            <div>
                <div style={{marginBottom:'1rem', fontSize:'0.9rem'}}>{props.lang === 'en' ? 'Leave us your info and we will contact you asap!' : '留下您的聯絡資訊，我們會盡快與您聯絡！'}</div>

                <div>{props.lang === 'en' ? 'Product Name' : '商品名稱'}</div>
                <div style={{marginBottom:'1rem'}}><input type='text' defaultValue={props.title} style={{width:'20rem', height:'2.5rem', padding:'0.5rem', border:'1px solid #CCCCCC', borderRadius:'5px'}} readOnly /></div>

                <div>{props.lang === 'en' ? 'Model Num' : '商品型號'}</div>
                <div style={{marginBottom:'1rem'}}><input type='text' defaultValue={props.modelNum} style={{width:'20rem', height:'2.5rem', padding:'0.5rem', border:'1px solid #CCCCCC', borderRadius:'5px'}} readOnly /></div>

                <div>{props.lang === 'en' ? 'Product URL' : '商品連結'}</div>
                <div style={{marginBottom:'1rem'}}><input type='text' defaultValue={props.productUrl} style={{width:'20rem', height:'2.5rem', padding:'0.5rem', border:'1px solid #CCCCCC', borderRadius:'5px'}} readOnly /></div>

                <div>{props.lang === 'en' ? 'Name' : '姓名'}＊</div>
                <div style={{marginBottom:'1rem'}}><input id="inquire-name" type='text' value={props.inquireUsForm.name} onChange={(e) => props.handleInput('name', e.target.value)} style={{width:'20rem', height:'2.5rem', padding:'0.5rem', border:'1px solid #CCCCCC', borderRadius:'5px'}} /></div>

                <div>Email＊</div>
                <div style={{marginBottom:'1rem'}}><input id="inquire-email" type='email' value={props.inquireUsForm.email} onChange={(e) => props.handleInput('email', e.target.value)} style={{width:'20rem', height:'2.5rem', padding:'0.5rem', border:'1px solid #CCCCCC', borderRadius:'5px'}} /></div>

                {/* <div>{props.lang === 'en' ? 'Phone' : '電話'}＊</div>
                <div style={{marginBottom:'1rem'}}><input id="inquire-phone" type='text' value={props.inquireUsForm.phone} onChange={(e) => props.handleInput('phone', e.target.value)} style={{width:'20rem', height:'2.5rem', padding:'0.5rem', border:'1px solid #CCCCCC', borderRadius:'5px'}} /></div> */}

                {/* <div>{props.lang === 'en' ? 'Best time/method to contact' : '方便聯繫時間/方式'}＊</div>
                <div style={{marginBottom:'1rem'}}>
                    <Select 
                        id="inquire-time"
                        options={opts__inquire_time} 
                        value={props.inquireUsForm.time_value}
                        onChange={handleChangeInquireTimeSelect}
                    />
                </div> */}
                {/* <div style={{marginBottom:'1rem'}}><input id="inquire-time" type='text' value={props.inquireUsForm.time} onChange={(e) => props.handleInput('time', e.target.value)} style={{width:'20rem', height:'2.5rem', padding:'0.5rem', border:'1px solid #CCCCCC', borderRadius:'5px'}} /></div> */}

                <div>{props.lang === 'en' ? 'Message' : '留言'}</div>
                <div style={{marginBottom:'1rem'}}><textarea type='text' value={props.inquireUsForm.message} onChange={(e) => props.handleInput('message', e.target.value)} style={{width:'20rem', padding:'0.5rem', border:'1px solid #CCCCCC', borderRadius:'5px'}} rows={3}></textarea></div>
            </div>
        </div>
    );
};

export default ProductInquireUsModal;
