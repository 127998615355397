import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import classes from './CartDrawer.module.css';
import 'react-notifications/lib/notifications.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import SpinnerBackdrop from '../UI/Spinner/SpinnerBackdrop';
import ProductInquireUsModal from '../../components/Product/ProductInquireUsModal';

import { urlBaseImgStatic, urlHostEcom } from '../../helpers/url';
import { axiosHttp, numberWithCommas, check_valid_email } from '../../helpers/base';
import { 
    cart_calculate_best_price_total, 
    cart_sort_listCart_en, 
    cart_sort_listCart_zh,
    cart_validate_reasonCannotPay,
} from '../../helpers/order';

const CartDrawer = (props) => {
    let unique_key = 0;

    const [stateLoading, setLoading] = useState(false);
    const [stateCart, setCart] = useState(null);
    const [stateReasonCannotPay, setReasonCannotPay] = useState(null);
    const [stateModalShowInquireUsForm, setModalShowInquireUsForm] = useState(false);
    const [stateInquireUsForm, setInquireUsForm] = useState({
        title: '',
        modelNum: '',
        productUrl: '',
        name: '',
        email: '',
        phone: '',
        time: '',
        message: '',
    });

    useEffect(() => {
        for (let a in stateCart) {
            if (document.getElementById('id-qty-' + stateCart[a].idVar)) {
                document.getElementById('id-qty-' + stateCart[a].idVar).value = stateCart[a].qty;
            }
        }
        // checkCartItemStatus();
    // eslint-disable-next-line
    }, [stateCart]);

    useEffect(() => {
        populateCart();
    }, []);

    const populateCart = () => {
        // setMethod('');
        setLoading(true);

        // if there is local cart, use that
        // else use cart from db
        let cart = localStorage.getItem('cart');

        axiosHttp('post', '/order/calculateCartView', false, {
            cart: cart,
            idClient: props.idUser,
        })
        .then((res) => {
            // setDeal(res.data.listDeal);
            if (props.lang === 'en') {
                setCart(res.data.listCartView.sort(cart_sort_listCart_en));
            }
            else {
                setCart(res.data.listCartView.sort(cart_sort_listCart_zh));
            }
            // setVendorPay(res.data.vendorPay);
            // setPayCardCharge(res.data.payCardCharge);
            setLoading(false);
            
            // let showCOD = false;
            // for (let a in res.data.listCartView) {
            //     if (res.data.listCartView[a].isAllowCOD) {
            //         showCOD = true;
            //         break;
            //     }
            // }
            // if (res.data.preExistingOrdersCount > 0) {
            //     showCOD = true;
            // }
            // setShowCOD(showCOD);
        });
    };

    const handleBlurQty = (idProduct, idVar, qty, checked) => {
        let cart = localStorage.getItem('cart');

        axiosHttp('post', '/order/updateCartQtyByVar', true, {
            idClient: props.idUser,
            cart: cart,
            idProduct: idProduct,
            idVar: idVar,
            qty: qty,
            checked: checked,
        })
        .then((res) => {
            if (props.idUser === null) {
                if (res.data.cart === '') {
                    localStorage.removeItem('cart');
                }
                else {
                    localStorage.setItem('cart', res.data.cart);
                }
            }
            populateCart();
            // props.onUpdateCart();
        });
    };

    const handleCheckout = () => {
        let {reasonCannotPay, count_checked} = cart_validate_reasonCannotPay(stateCart);
        setReasonCannotPay(reasonCannotPay);

        if (count_checked === 0) {
            alert("Please select items for checkout.");
            return;
        }
        // if (reasonCannotPay !== null) {
        //     if (reasonCannotPay.indexOf('inquiry-') > -1) {
        //         alert("There are items selected for checkout that are for Price Inquiry.\n\nOur sales team will contact you after you've placed the order!");
        //     }
        //     else if (reasonCannotPay.indexOf('shipspecial-') > -1) {
        //         alert("There are items selected for checkout that require special handling.\n\nOur sales team will contact you regarding shipping fees after you've placed the order!");
        //     }
        //     else if (reasonCannotPay.indexOf('overinventory-') > -1) {
        //         alert("There are items selected for checkout that are out of stock.\n\nOur sales team will contact you after you've placed the order!");
        //     }
        // }
        window.location.href = '/checkout';
    };

    const handleClickInquireUsForm = (data) => {
        setInquireUsForm({
            title: data.item.productTitleEn,
            modelNum: data.item.modelNum,
            productUrl: urlHostEcom + '/product/' + data.item.idProduct + '/' + data.item.idVar,
            name: '',
            email: '',
            phone: '',
            time: {},
            message: '',    
        });
        setModalShowInquireUsForm(true);
    };

    const handleInputInquireUsForm = (field, value) => {
        setInquireUsForm(prev => {
            let next = {...prev};
            for (let a in next) {
                if (a === field) {
                    next[a] = value;
                }
            }
            return next;
        });
    };

    const handleSubmitInquireUsForm = async () => {
        if (stateInquireUsForm.name.trim() === '') {
            NotificationManager.error(props.lang === 'en' ? 'Please enter a name' : '請輸入 姓名', null, 1000);
            document.getElementById('inquire-name').focus();
            return;
        }
        if (stateInquireUsForm.email.trim() === '') {
            NotificationManager.error(props.lang === 'en' ? 'Please enter an email' : '請輸入 Email', null, 1000);
            document.getElementById('inquire-email').focus();
            return;
        }
        if (!check_valid_email(stateInquireUsForm.email)) {
            NotificationManager.error(props.lang === 'en' ? 'Please enter a valid email' : '請輸入正確格式 Email', null, 1000);
            document.getElementById('inquire-email').focus();
            return;
        }
        // if (stateInquireUsForm.phone.trim() === '') {
        //     NotificationManager.error(props.lang === 'en' ? 'Please enter a phone' : '請輸入 電話', null, 1000);
        //     document.getElementById('inquire-phone').focus();
        //     return;
        // }
        // if (!stateInquireUsForm.time.label) {
        //     NotificationManager.error(props.lang === 'en' ? 'Please enter a Best time/method to contact' : '請輸入 方便聯繫時間/方式', null, 1000);
        //     document.getElementById('inquire-time').focus();
        //     return;
        // }

        // let response = window.confirm(props.lang === 'en' ? 'Please ensure the details are correct so we can contact you:\n\nName: ' + stateInquireUsForm.name + '\nEmail: ' + stateInquireUsForm.email + '\nPhone: ' + stateInquireUsForm.phone + '\nBest time to contact: ' + stateInquireUsForm.time.label + '\nMessage: ...' : '確認資訊正確:\n\n姓名: ' + stateInquireUsForm.name + '\nEmail: ' + stateInquireUsForm.email + '\n電話: ' + stateInquireUsForm.phone + '\n方便聯繫時間: ' + stateInquireUsForm.time.label + '\n留言: ...');
        let response = window.confirm(props.lang === 'en' ? 'Please ensure the details are correct so we can contact you:\n\nName: ' + stateInquireUsForm.name + '\nEmail: ' + stateInquireUsForm.email + '\nMessage: ...' : '確認資訊正確:\n\n姓名: ' + stateInquireUsForm.name + '\nEmail: ' + stateInquireUsForm.email + '\n留言: ...');
        if (response === false) { return; }

        let res = await axiosHttp('post', '/order/sendMailInquireUs', false, {
            email: stateInquireUsForm.email.trim(),
            lang: props.lang,
            title: stateInquireUsForm.title.trim(),
            modelNum: stateInquireUsForm.modelNum.trim(),
            url: stateInquireUsForm.productUrl.trim(),
            name: stateInquireUsForm.name.trim(),
            phone: stateInquireUsForm.phone.trim(),
            time: stateInquireUsForm.time.label,
            message: stateInquireUsForm.message.trim(),
        });
        if (res && res.data && res.data.message && res.data.message === "COMPLETED") {
            NotificationManager.success(props.lang === 'en' ? 'Email sent.  We will contact you shortly. ' : 'Email送出成功，我們會盡快與您聯絡', null, 2000);
            setModalShowInquireUsForm(false);
            return;
        }
        NotificationManager.error(props.lang === 'en' ? 'Error in sending' : '送出發生錯誤', null, 1000);
    };
    
    return (
        <React.Fragment>
            {stateLoading 
            ? <SpinnerBackdrop opacity={0.5} /> 
            : null}

            <Modal show={stateModalShowInquireUsForm} onHide={() => setModalShowInquireUsForm(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props.lang === 'en' ? 'Inquire Us' : '詢問'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalBodyWrapper>
                        <ProductInquireUsModal
                            lang={props.lang}
                            title={stateInquireUsForm.title}
                            modelNum={stateInquireUsForm.modelNum}
                            productUrl={stateInquireUsForm.productUrl}
                            inquireUsForm={stateInquireUsForm}
                            handleInput={handleInputInquireUsForm}                    
                        />
                    </ModalBodyWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSubmitInquireUsForm()}>{props.lang === 'en' ? 'Send' : '送出'}</Button> 
                </Modal.Footer>
            </Modal>

            <header className={classes.Header}>
                <b>Cart ({stateCart ? stateCart.length : 0})</b>
            </header>

            <table style={{width:'95%', margin:'1rem auto'}}>
                <tbody>
                    {stateCart === null 
                    ? null 
                    : (
                        stateCart.length === 0 
                        ? (<tr><td style={{textAlign:'center'}}>No Items in Cart yet</td></tr>) 
                        : (
                            stateCart.map((item, index) => {
                                return (
                                    <tr key={unique_key++}>
                                        <td>
                                            {item.checked 
                                            ? (<FontAwesomeIcon icon={faCheckSquare} onClick={() => handleBlurQty(item.idProduct, item.idVar, item.qty, '')} size="lg" />) 
                                            : (<FontAwesomeIcon icon={faSquare} onClick={() => handleBlurQty(item.idProduct, item.idVar, item.qty, 'checked')} size="lg" />)}
                                        </td>
                                        <td style={{width:'95px', padding:'0.5rem'}}>
                                            <img width="95" src={urlBaseImgStatic + 'prod/' + item.idProduct + '-' + item.sortImgPrimary + (item.timestamp ? ('-' + item.timestamp) : '') + '-sm.png'} alt="&nbsp;&nbsp;無圖片..." />
                                        </td>
                                        <td style={{padding:'0.5rem', verticalAlign:'top', paddingBottom:'2rem'}}>
                                            <div>{props.lang === 'en' ? item.productTitleEn : item.productTitleZh}</div>
                                            <div>{props.lang === 'en' ? item.varTitleEn : item.varTitleZh}</div>
                                            <div>{item.priceUsdBest > 0 ? '$' + numberWithCommas(item.priceUsdBest.toFixed(2)) : 'Price Inquiry'}</div>
                                            <div style={{marginTop:'0.5rem', display:'flex', justifyContent:'space-between'}}>
                                                <div>
                                                    <Button onClick={() => handleBlurQty(item.idProduct, item.idVar, item.qty - 1, item.checked ? 'checked' : '')} size='sm' style={{backgroundColor:'var(--wnw2Background)', border:'0', color:'black', height:'3rem', marginTop:'-5px', marginRight:'0.5rem'}}><FontAwesomeIcon icon={faMinus} size="sm" /></Button>
                                                    <input id={'id-qty-' + item.idVar} type='number' step={1} onBlur={(e) => handleBlurQty(item.idProduct, item.idVar, e.currentTarget.value)} style={{border:'1px solid #AAAAAA', borderRadius:'5px', margin:'0', width:'3rem', height:'3rem', textAlign:'center'}} />
                                                    <Button onClick={() => handleBlurQty(item.idProduct, item.idVar, item.qty + 1, item.checked ? 'checked' : '')} size='sm' style={{backgroundColor:'var(--wnw2Background)', border:'0', color:'black', height:'3rem', marginTop:'-5px', marginLeft:'0.5rem'}}><FontAwesomeIcon icon={faPlus} size="sm" /></Button>
                                                </div>
                                                <div>
                                                    <Button onClick={() => handleBlurQty(item.idProduct, item.idVar, 0)} size='sm' style={{backgroundColor:'red', border:'0', color:'white', height:'3rem', marginTop:'-5px', marginLeft:'0.5rem'}}><FontAwesomeIcon icon={faTrashAlt} size="sm" /></Button>
                                                </div>
                                            </div>
                                            {item.hasOverInventory 
                                            ? (<div>Out of stock - <a href='javascript:void(0)' onClick={() => handleClickInquireUsForm({item:item})}>Contact Us!</a></div>) 
                                            : null}
                                        </td>
                                    </tr>
                                )
                            })
                        )
                    )}
                </tbody>
            </table>

            <hr style={{marginTop:'3rem'}} />

            <div style={{textAlign:'center', marginBottom:'2rem'}}>
                <div style={{display:'flex', justifyContent:'space-between', width:'85%', margin:'1rem auto'}}>
                    <h3><b>Total:</b></h3>
                    <h3 style={{textAlign:'right'}}><b>${numberWithCommas(cart_calculate_best_price_total(stateCart))}</b></h3>
                </div>
                <Button onClick={() => handleCheckout()} variant='primary'>Checkout</Button>
            </div>
        </React.Fragment>
    );
};

export default CartDrawer;

const ModalBodyWrapper = styled.div`
    img {
        max-width: 100% !important;
        height: auto;
    }

    iframe {
        max-width: 100% !important;
        height: auto;
    }
`;
